var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-table-simple',{attrs:{"responsive":""}},[_c('b-tbody',[_c('b-tr',[_c('b-th',{staticClass:"text-center",attrs:{"colspan":"2"}},[_vm._v("RECURSOS BIBLIOGRÁFICOS ")])],1),_vm._l((_vm.bibliographicResourceTypesFilter),function(bibliography_type){return _c('b-tr',{key:`bibliography-${bibliography_type.id}`},[_c('b-td',{staticStyle:{"width":"25%"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"flex-grow-1 text-center"},[_c('strong',{staticClass:"mr-1"},[_vm._v(_vm._s(bibliography_type.name)+" ")]),_c('b-icon-exclamation-circle',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary",value:(
                'Puede añadir hasta ' +
                bibliography_type.max_amount +
                ' recursos bibliográficos'
              ),expression:"\n                'Puede añadir hasta ' +\n                bibliography_type.max_amount +\n                ' recursos bibliográficos'\n              ",modifiers:{"v-secondary":true}}]})],1),_c('div',{staticClass:"mt-1"},[(
                _vm.allows_crud &&
                _vm.bibliography.filter((b) => b.type == bibliography_type.id)
                  .length < bibliography_type.max_amount &&
                _vm.oldMatter &&
                !_vm.oldMatter.is_closed &&
                _vm.isClosedOldMatterPartial(bibliography_type)
              )?_c('div',[_c('ButtonMedium',{attrs:{"tooltip_text":`Agregue los Recursos por separado`,"click_button":() =>
                    _vm.$bvModal.show(
                      `create-bibliographic-resource-modal-${bibliography_type.id}-${_vm.matter_form_view}`
                    ),"text_button":``,"icon":'plus',"variant":'primary',"custom_class":`p-0 mr-1 square-button`}}),_c('b-modal',{attrs:{"id":`create-bibliographic-resource-modal-${bibliography_type.id}-${_vm.matter_form_view}`,"title":"Crear Recurso Bibliográfico","size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_c('NewRichTextEditor',{attrs:{"Type":bibliography_type},on:{"save":_vm.createBibliography,"close":function($event){return _vm.closeModal(bibliography_type.id)}}})],1)],1):(
                _vm.allows_crud &&
                _vm.oldMatter &&
                !_vm.oldMatter.is_closed &&
                _vm.isClosedOldMatterPartial(bibliography_type)
              )?_c('div',[_c('ButtonMedium',{attrs:{"tooltip_text":`Supero el máximo de recursos bibliográficos`,"text_button":``,"disabled_button":true,"icon":'plus',"variant":'primary',"custom_class":`p-0 mr-1 square-button`}})],1):_vm._e()])])]),_c('b-td',[_c('div',_vm._l((_vm.bibliography.filter(
              (b) => b.type == bibliography_type.id
            )),function(biblio){return _c('div',{key:`bibliography-${biblio.id}`,staticClass:"border-resource"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"my-1 p-1 w-100 rich-text-content support-resource-content",domProps:{"innerHTML":_vm._s(biblio.description)}}),(
                  _vm.allows_crud &&
                  _vm.oldMatter &&
                  !_vm.oldMatter.is_closed &&
                  _vm.isClosedOldMatterPartial(bibliography_type)
                )?_c('div',{staticClass:"container-btn-blibliographic"},[_c('div',{staticClass:"d-flex p-1"},[_c('ButtonSmall',{class:`w-30`,attrs:{"tabindex":"-1","tooltip_text":`Editar`,"click_button":() =>
                        _vm.$bvModal.show(
                          `edit-bibliographic-resource-modal-${biblio.id}-${_vm.matter_form_view}`
                        ),"icon":'square'}}),_c('ButtonSmall',{class:`w-30`,attrs:{"tabindex":"-1","click_button":() => _vm.deleteBibliography(biblio, bibliography_type.name),"tooltip_text":`Eliminar`,"icon":'trash'}})],1),_c('b-modal',{attrs:{"id":`edit-bibliographic-resource-modal-${biblio.id}-${_vm.matter_form_view}`,"title":"Editar Recurso Bibliográfico","size":"lg","hide-footer":"","ignore-enforce-focus-selector":"#sidebar-right"}},[_vm._v(" Copie y edite los contenidos por separado "),_c('NewRichTextEditor',{attrs:{"Object":biblio,"Text":biblio.description},on:{"save":_vm.updateBibliography,"close":function($event){return _vm.closeModal(biblio.id)}}})],1)],1):_vm._e()])])}),0)])],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }